<template>
  <div class="balance">
    <div class="balance_title">
      <div class="top">
        <div class="balance_value">{{ balance_money }}</div>
      </div>
      <div class="buttom">
        <div>余额(元)</div>
        <div class="detail" @click="hanldeShowDetail">
          <div>余额详情</div>
          <img
            class="img"
            :class="showDetail ? 'img-star' : 'img-end'"
            src="../../assets/image/my/y.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <transition name="van-fade">
      <div v-show="showDetail" class="detail-list">
        <div class="item" v-for="(item, index) of balanceList" :key="index">
          <div>{{ item.fee_name }}</div>
          <div class="balance_number">{{ item.balance }}</div>
        </div>
      </div>
    </transition>
    <div class="item-box">
      <div class="filter">
        <span>变动记录</span>
        <div @click="show = true">
          <span>{{ currType.name }}</span>
          <img src="../../assets/image/my/xx.png" alt="" />
        </div>
      </div>
      <!-- list -->
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list-box">
          <div class="list" @click="goUrl(item)" v-for="item in dataList" :key="item">
            <div class="left">
              <!-- <div class="title" v-if="item.scene == 30">提现:</div>
              <div class="title" v-else-if="item.scene == 20">商铺租金抵扣:</div>
              <div class="title" v-else-if="item.scene == 10">商户转入:</div>
              <div class="title" v-else>默认:</div> -->
              <div class="title">{{ item.describe }}</div>
              <div class="time">{{ item.create_at }}</div>
            </div>
            <div class="right">
              <div>
                <!-- <div class="paychar add" v-if="item.scene == 10" >{{item.money}}</div> -->
                <!-- <div class="paychar" style="color: #85c226;" v-if="item.money > 0">{{item.money}}</div> -->
                <div class="paychar" style="color: #85c226" v-if="item.money > 0">
                  {{ item.money }}
                </div>
                <div class="paychar" style="color: #e90000" v-else>{{ item.money }}</div>

                <div class="ye">
                  <span>余额:</span>
                  <span>{{ item.balance_money }}</span>
                </div>
              </div>
              <img src="../../assets/image/my/y.png" alt="" />
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      close-on-click-action
      @select="onSelect"
    />
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from 'vue';

const ctx = inject('ctx');
const $require = inject('$require');
const page = ref({
  page_num: 1,
  page_size: 10
});
const total = ref(0);
let balance_money = ref(0); //总余额
const balanceList = ref([]);
let currType = ref({
  name: '全部',
  value: ''
});
let loading = ref(false);
let finished = ref(false);
let dataList = ref('');
const show = ref(false);
const actions = [
  {
    name: '全部',
    value: ''
  },
  {
    name: '收入',
    value: 10
  },
  {
    name: '支出',
    value: 20
  }
];
const showDetail = ref(false);
const hanldeShowDetail = () => {
  showDetail.value = !showDetail.value;
};
/***
 * 获取页面数据
 * */
let getList = (scroll = false) => {
  loading.value = true;
  $require
    .HttpPost('/h5/o_balance/balanceBill', {
      ...page.value,
      scene: currType.value.value
    })
    .then((res) => {
      total.value = res.data.total;
      loading.value = false;
      //获取总余额
      balanceList.value = res.data?.balance_money ?? [];
      let temp = 0;
      balanceList.value.forEach((item) => {
        temp += item.balance;
      });
      balance_money.value = Math.round(temp * 100) / 100;
      const resList = res.data?.list ?? [];
      if (scroll) {
        dataList.value = dataList.value.concat(resList);
      } else {
        dataList.value = resList;
      }
    });
};
onMounted(() => {
  getList();
});
/**
 * 上拉加载
 */
let onLoad = () => {
  if (dataList.value.length >= total.value) {
    finished.value = true;
    return;
  }
  page.value.page_num += 1;

  getList(true);
};
const onSelect = (item) => {
  finished.value = false;
  currType.value = item;
  show.value = false;
  page.value.page_num = 1;
  getList();
};
// 跳转
function goUrl(row) {
  ctx.router.push(`/account/chargeDetail?id=${row.log_id}`);
}
</script>

<style lang="scss">
.balance {
  height: 100vh;
  background: #f9f9f9;
  .balance_title {
    box-sizing: border-box;
    font-size: 28px;
    border-radius: 20px;
    margin: 24px;
    height: 200px;
    background: #85c226;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 30px;

    .top {
      font-size: 60px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #ffffff;
    }
    .buttom {
      font-size: 28px;
      color: #ffffff;
      font-family: PingFang SC;
      display: flex;
      justify-content: space-between;
      .detail {
        display: flex;
        align-items: center;
        div {
          color: #000000;
        }
        .img {
          width: 14px;
          margin-left: 10px;
          vertical-align: middle;
          filter: brightness(0.2);
          transition: all 0.5s;
          margin-left: 10px;
        }
        .img-star {
          transform: rotate(-90deg);
        }
        .img-end {
          transform: rotate(90deg);
        }
      }
    }
  }
  .detail-list {
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 24px;
    font-size: 28px;
    box-shadow: 0px 7px 9px 0px #00000004;
    .item {
      padding: 0 60px 0 24px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
      }
      .balance_number {
        color: #000000;
      }
    }
  }
  .item-box {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    .filter {
      width: 100%;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      color: #000000;

      div {
        width: 240px;
        height: 96px;
        background: #efefef;
        border-radius: 48px;
        text-align: center;
        line-height: 96px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }

      img {
        width: 22px;
        height: 13px;
        margin-left: 63px;
      }
    }
  }

  .list-box {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 20px;
    padding: 0 30px;
    box-sizing: border-box;

    .list {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      // height: 126px;
      border-bottom: 1px solid #efefef;
      padding: 30px 0;
      box-sizing: border-box;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 13px;
        height: 22px;
      }

      .left {
        .title {
          font-size: 28px;
          font-family: PingFang SC;
          // font-weight: 500;
          // color: #000000;
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          // color: red;
        }

        .time {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          margin-top: 24px;
        }
      }

      .right {
        display: flex;
        align-items: flex-start;
        text-align: right;
        flex-shrink: 0;
        .paychar {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }

        .add {
          color: #85c226;
        }

        .ye {
          // width: 300px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          margin-top: 24px;
        }

        img {
          width: 13px;
          height: 22px;
          margin-left: 28px;
          vertical-align: middle;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
